<!-- @format -->

<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group label="Old password" label-for="account-old-password">
            <b-input-group class="input-group-merge">
              <b-form-input id="account-old-password" v-model="passwordValueOld" name="old-password" :type="passwordFieldTypeOld" placeholder="Please enter your old password" />
              <b-input-group-append is-text>
                <feather-icon :icon="passwordToggleIconOld" class="cursor-pointer" @click="togglePasswordOld" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="5">
          <b-form-group label-for="account-new-password" label="New password">
            <b-input-group class="input-group-merge">
              <b-form-input id="account-new-password" v-model="newPasswordValue" :type="passwordFieldTypeNew" name="new-password" placeholder="Please enter your new password" />
              <b-input-group-append is-text>
                <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="7">
          <b-form-group label-for="account-retype-new-password" label="Re-enter new password">
            <b-input-group class="input-group-merge">
              <b-form-input id="account-retype-new-password" v-model="RetypePassword" :type="passwordFieldTypeRetype" name="retype-password" placeholder="Please enter the new password again" />
              <b-input-group-append is-text>
                <feather-icon :icon="passwordToggleIconRetype" class="cursor-pointer" @click="togglePasswordRetype" />
              </b-input-group-append>
            </b-input-group>
            <div v-if="RetypePassword.length > 0">
              <span v-if="RetypePassword != newPasswordValue">The new password does not match the new password entered again</span>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="5">
          <b-form-group label-for="account-retype-new-password" label="Email">
            <b-input-group class="input-group-merge">
              <b-form-input v-model="email" disabled name="code" placeholder="Please enter your email" />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="7">
          <b-form-group label-for="account-retype-new-password" label="Verify code">
            <b-input-group class="input-group-merge">
              <b-form-input v-model="code" name="code" placeholder="Please enter verify code" />
              <b-input-group-append is-button>
                <b-button @click="sendCode" :disabled="sendCodeDisabled" variant="primary" size="sm" class="mr-2" ref="btnCode">Send verify code</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1" @click="changePassword" :disabled="isLoading"> Confirm </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mt-1"> Reset content </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BFormInvalidFeedback } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
  },
  props: {
    addr: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      code: '',
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      isLoading: false,
      email: undefined,
      sendCodeDisabled: false,
      timer: null,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.email = JSON.parse(localStorage.getItem('userData')).email
    })
  },
  methods: {
    // 倒计时 3分钟内只能发送一次验证码
    countDown() {
      const dom = this.$refs.btnCode
      let time = 3 * 60 * 1000
      this.sendCodeDisabled = true

      const timer = setInterval(() => {
        if (time > 0) {
          time -= 1000
          dom.innerText = `Resend after${Math.floor(time / 1000)}seconds`
        } else {
          clearInterval(timer)
          dom.innerText = 'Send verify code'
          this.sendCodeDisabled = false
        }
      }, 1000)
      // this.sendCodeDisabled = true
      // this.timer = setInterval(() => {
      //   if (this.timer) {
      //     this.sendCodeDisabled = true
      //     this.timer = null
      //   }
      // }, 180000)
    },
    sendCode() {
      axiosIns
        .get('/login/sendEmailCode2', {
          params: {
            email: this.email,
          },
        })
        .then(res => {
          if (res.code == 0) {
            this.sendCodeDisabled = true
            this.$bvToast.toast(res.msg, {
              autoHideDelay: 3000,
              appendToast: true,
              solid: true,
              toaster: 'b-toaster-top-center',
              variant: 'success',
            })
            this.countDown()
          } else {
            this.$bvToast.toast(res.msg, {
              autoHideDelay: 3000,
              appendToast: true,
              solid: true,
              toaster: 'b-toaster-top-center',
              variant: 'danger',
            })
          }
        })
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    changePassword() {
      this.isLoading = true
      if (!this.passwordValueOld) {
        this.$bvToast.toast('Please enter old password', {
          title: 'Tips',
          variant: 'warning',
          solid: true,
          appendToast: true,
          autoHideDelay: 2000,
        })
        this.isLoading = false
      } else if (!this.newPasswordValue) {
        this.$bvToast.toast('Please enter new password', {
          title: 'Tips',
          variant: 'warning',
          solid: true,
          appendToast: true,
          autoHideDelay: 2000,
        })
        this.isLoading = false
      } else if (this.RetypePassword != this.newPasswordValue) {
        this.$bvToast.toast('The new password does not match the new password entered again', {
          title: 'Tips',
          variant: 'warning',
          solid: true,
          appendToast: true,
          autoHideDelay: 2000,
        })
        this.isLoading = false
      } else {
        axiosIns
          .post('/user/editPassword', {
            old_password: this.passwordValueOld,
            new_password: this.newPasswordValue,
            code: this.code,
          })
          .then(response => {
            if (response.code == 0) {
              this.$bvModal.msgBoxOk('Change password successfully')
                .then(value => {
                  this.$router.push({ name: this.addr || 'vip_info' })
                })
                .catch(err => {
                  // An error occurred
                })
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Tips',
                variant: 'warning',
                solid: true,
                appendToast: true,
                autoHideDelay: 2000,
              })
              this.isLoading = false
            }
          })
          .catch(error => {
            this.$bvToast.toast(error.message, {
              title: 'Tips',
              variant: 'warning',
              solid: true,
              appendToast: true,
              autoHideDelay: 2000,
            })
            this.isLoading = false
          })
      }
      console.log(this.passwordValueOld, this.newPasswordValue, this.RetypePassword)
    },
  },
}
</script>
